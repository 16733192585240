<template>
  <div class="content">
    <div class="maintenance">
      <LogoRvezy class="rvezy-logo mb-2" />
      <p>{{ t('content') }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
</script>

<style lang="css" scoped>
body,
html {
  height: 100%;
  width: 100%;
}
.maintenance {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.25em;
}
.content {
  height: 100%;
  width: 100%;
  background-color: #5471b9;
}
</style>

<i18n lang="json">
{
  "en": {
    "content": "We’re currently performing maintenance on our site, please check back later!"
  },
  "fr": {
    "content": "Nous effectuons actuellement une maintenance sur notre site, veuillez revenir plus tard !"
  }
}
</i18n>
